import React, { Component } from "react";
import styled from "styled-components";
import NCarousel from 'nuka-carousel';
import Layout from "../layout/Layout";
import { housingBuildings } from '../data/housingBuildings';

const InvestitonsWrapper = styled.div`
  width: 100vw;
  font-size: 14px;

  ${({ theme }) => theme.media.investitionsDesktop} {
    display: flex;
    height: 100%;
    
  }
`;

const InvestitonsContent = styled.div`
    padding-top: 8rem;
    position: relative;
    background: #fafafa;
    padding-bottom: 4rem;

    ${({ theme }) => theme.media.investitionsDesktop} {
      display: block;
      width: 25%;
      min-height: 100%;
    }
`

const DetailsBasicContainer = styled.div`
  display: block;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 1.5rem 1.5rem;

  ${({ theme }) => theme.media.investitionsDesktop} {
    padding: 0 4rem 4rem;
  }
`;

const DetailsBasicHeader = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin-bottom: 2rem;
`;

const DetailsBasicContent = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  padding-top: 1rem;
`;

const InvestitonsImageContainer = styled.div`
  position: relative;
  width: auto;
  background: #fff;
  padding-bottom: 5rem;
  
  ${({ theme }) => theme.media.investitionsDesktop} {
    width: 75%;
    padding: 15rem 10rem 14rem 10rem;
    max-height: 100vh;
  }

  .slider-control-bottomcenter {
    bottom: -2.5rem !important;
  }

  button {
    outline: none !important;
  }
`;

const ImageContainer = styled.div`
    width: 100%;
    padding-bottom: 60%;
    position: relative;
    
    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
`;

const InvestitionDetails = styled.div`
    width: 100%;
    display: flex;
    align-items: baseline;
`;

const IconLocation = styled.div`
    margin-right: 10px;
    height: 20px;
    width: 20px;
    background-image: url('/images/icons/location.svg');
    background-size: contain;
`;

const Description = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  line-height: 10px;
`;

const Details = props => (
  <>
    <InvestitonsImageContainer>
      <NCarousel
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
        wrapAround
      >
        {props.images.map((image) => (
          <ImageContainer>
            <img src={image} alt={props.name} />
          </ImageContainer>
        ))}
      </NCarousel>
    </InvestitonsImageContainer>
  </>
)

class Investitions extends Component {
  state = { activeItem: 0 };

  handleClick = (index) => { this.setState({ activeItem: index }) };

  render() {
    const { activeItem } = this.state;
    return (
      <Layout>
        <InvestitonsWrapper>
          <InvestitonsContent>
            <DetailsBasicContainer>
              <DetailsBasicHeader>
                Budynek na sprzedaż
              </DetailsBasicHeader>
              <InvestitionDetails>
                <IconLocation />
                <Description>Mikołów</Description>
                </InvestitionDetails>
                <InvestitionDetails>
              </InvestitionDetails>
              <DetailsBasicContent>
                Oferujemy na sprzedaż 5 segmentów w zespole budynków jednorodzinnych przy ulicy Jaśminowej w Mikołowie. Kameralne osiedle składa się z budynków o dwóch oraz trzech segmentach z niezależnymi podjazdami oraz ogródkami. Inwestycja jest bardzo dobrze połączona z dostępem do aglomeracji oraz jednocześnie znajduje się w zielonej części Mikołowa. Nowoczesna architektura i przemyślany układ funkcjonalno-użytkowy pozwoli poczuć się bardzo komfortowo w oferowanych budynkach.
              </DetailsBasicContent>
            </DetailsBasicContainer>
          </InvestitonsContent>
          <Details {...housingBuildings[activeItem]} />
        </InvestitonsWrapper>
      </Layout>
    );
  }
}

export default Investitions;